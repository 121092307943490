<template>
  <div class="home_warp">
    <!-- 顶部导航 -->
    <titleNav title="职业证书报名条件" :left-arrow="true" left-text="" />
    <!-- 内容 -->
    <div class="title">
        一、证书等级及补贴费用
    </div>
    <div class="blocks">
        <div class="header_b">
            <div class="left_b">证书等级</div>
            <div class="right_b">补贴费用</div>
        </div>
        <div class="body_b">
            <div class="left_y">初级 (五级)</div>
            <div class="right_y">1000元</div>
        </div>
        <div class="body_b">
            <div class="left_y">中级 (四级)</div>
            <div class="right_y">1500元</div>
        </div>
        <div class="body_b">
            <div class="left_y">高级 (三级)</div>
            <div class="right_y">2000元</div>
        </div>
    </div>
    <div class="title">
        二、职业技能报名条件
    </div>
    <div class="blocks">
        <div class="header_b">
            <div class="left_b">客户服务管理师</div>
        </div>
        <div class="body_c">
            <div class="qua_y">报名条件（满足以下条件之一即可）：</div>
            <div class="qua_y">1.满足大专及以上学历毕业一年，不限专业</div>
        </div>
    </div>
    <div class="blocks">
        <div class="header_b">
            <div class="left_b">电子商务师</div>
        </div>
        <div class="body_c">
            <div class="qua_y">报名条件（满足以下条件之一即可）：</div>
            <div class="qua_y">1.连续从事本职业工作6年以上</div>
            <div class="qua_y">2.具有本专业高职、高级技工学校毕业证书</div>
            <div class="qua_y">3.具有本专业大专及以上毕业证书</div>
            <div class="qua_y">4.具有非本专业大专及以上学历，并连续从事本职业工作1年以上</div>
        </div>
    </div>
    <div class="blocks">
        <div class="header_b">
            <div class="left_b">计算机程序员</div>
        </div>
        <div class="body_c">
            <div class="qua_y">报名条件（满足以下条件之一即可）：</div>
            <div class="qua_y">1.连续从事本职业工作5年以上（高中及以上学历）</div>
            <div class="qua_y">2.取得本职业高职毕业证并连续从事本职业工作1年以上</div>
            <div class="qua_y">3.取得相关专业本科及以上毕业证并连续从事本职业工作1年以上</div>
        </div>
    </div>
    <div class="blocks">
        <div class="header_b">
            <div class="left_b">人力资源管理师</div>
        </div>
        <div class="body_c">
            <div class="qua_y">报名条件（满足以下条件之一即可）：</div>
            <div class="qua_y">1.累计从事本职业或相关职业工作4年（含）以上</div>
            <div class="qua_y">2.取得技工学校本专业或相关专业毕业证书（含尚未取得毕业证书的在校应届毕业生）；或取得经评估论证，以中级技能为培养目标的中等及以上职业学校本专业或相关专业毕业证书（含尚未取得毕业证书的在校应届毕业生）</div>
            <div class="qua_y"> 3.高等院校本专业或相关专业在校生（高中毕业或同等学历）</div>
        </div>
    </div>
    <div class="blocks">
        <div class="header_b">
            <div class="left_b">公共营养师四级</div>
        </div>
        <div class="body_c">
            <div class="qua_y">报名条件（满足以下条件之一即可）：</div>
            <div class="qua_y">1.取得相关职业五级/初级工职业资格证书（技能等级证书）后，累计从事笨职业或相关职业工作1年（含）以上，经本职业四级/中级工正规培训达到规定学时数，并取得结业证书 </div>
            <div class="qua_y">2.取得相关职业五级/初级工职业资格证书（技能等级证书）后，累计从事笨职业或相关职业工作4年（含）以上 </div>
            <div class="qua_y">3.累计从事本职业或相关职业工作6年（含）以上 </div>
            <div class="qua_y">4.取得技工学校本专业或相关专业毕业证书（含尚未取得毕业证书的在校应届毕业生）；或取得经评估论证，以中级技能为培养目标的中等及以上职业学校本专业或相关专业毕业证书（含尚未取得毕业证书的在校应届毕业生）</div>
        </div>
    </div>
    <div class="blocks">
        <div class="header_b">
            <div class="left_b">公共营养师三级</div>
        </div>
        <div class="body_c">
            <div class="qua_y">报名条件（满足以下条件之一即可）：</div>
            <div class="qua_y">1.具有大专及以上本专业或相关专业毕业证书（含尚未取得毕业证书的在校应届毕业生）</div>
            <div class="qua_y">2.具有大专及以上非相关专业毕业证书，累计从事本职业或相关职业工作2年（含）以上</div>
        </div>
    </div>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
// import floatBtn from "@/components/floatBtn.vue";
import { Button, Dialog, Icon, Toast, Field, Checkbox, CheckboxGroup } from "vant";
import { banner, price, indexs } from "@/network/index.js";
export default {
  components: {
    titleNav,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
        checked: true,
        tel:'',
        sms:'',
    };
  },
  created() {
      window.scrollTo(0,0);
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.home_warp {
//   height: 100%;
  background-color: #e9e9e9;
  position: relative;
  padding: 0 10px;
  .content{
      padding: 10px;
      font-size: 16px;
      text-indent:32px;
  }
  .title{
      font-size: 18px;
      font-weight: 600;
      line-height: 50px;
  }
  .blocks{
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 10px;
      .header_b{
          background-color: #4FB0DA;
          color: #fff;
          font-size: 18px;
          height: 44px;
          line-height: 44px;
          text-align: center;
          .left_b{
              width: 50%;
              display: inline-block;
          }
          .right_b{
              width: 50%;
              display: inline-block;
          }
      }
      .body_b{
          background-color: #ffffff;
          color: rgb(0, 0, 0);
          font-size: 15px;
          height: 44px;
          line-height: 44px;
          text-align: center;
          .left_y{
              width: 50%;
              display: inline-block;
          }
          .right_y{
              width: 50%;
              display: inline-block;
          }
      }
      .body_c{
          background-color: #ffffff;
          color: rgb(0, 0, 0);
          font-size: 15px;
          padding: 10px;
          .qua_y{
              line-height: 24px;
              text-align: left;
              width: 100%;
              box-sizing: border-box;
          }
      }
  }
}
</style>
